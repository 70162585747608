import { dateConvert } from "utilities2";

export const formSubmitValueMapper = (data = {}) => ({
  id_penerima_petty_cash: data?.id_penerima_petty_cash ?? null,
  // no_penerima_petty_cash: data?.no_penerima_petty_cash ?? null,
  tgl_penerima_petty_cash: dateConvert().getDashYMD(
    new Date(data.tgl_penerima_petty_cash)
  ),
  id_pekerja: data?.id_pekerja,
  id_unit_produksi: data?.id_unit_produksi,
  keterangan: data?.keterangan,
  // status_penerima_petty_cash: false,
  status_approval: data?.status_approval,
});
