import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";
import { CRUDLayout, BackButton, DataStatus } from "../../../../components";
import {
  RegistrasiKaryawanApi,
  JabatanApi,
  StatusKaryawanApi,
  LokasiKerjaApi,
  WilayahApi,
} from "../../../../api";
import {
  FormDataPribadi,
  FormDataKontak,
  FormDataBank,
  FormDataAlamat,
  FormDataKepegawaian,
  FormDataKeluarga,
  FormDataAkun,
  FormDataPendidikan,
} from "./Ubah";

const UbahKaryawan = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataKaryawan, setDataKaryawan] = useState([]);
  const [dataJabatan, setDataJabatan] = useState([]);
  const [dataJenjangPendidikan, setDataJenjangPendidikan] = useState([]);
  const [dataStatusKaryawan, setDataStatusKaryawan] = useState([]);
  const [dataLokasiKerja, setDataLokasiKerja] = useState([]);
  const [dataProvinsi, setDataProvinsi] = useState([]);
  const [dataBank, setDataBank] = useState([]);

  // fetch data
  const getInitialData = () => {
    setIsLoading(true);

    Axios.all([
      RegistrasiKaryawanApi.getOne(id),
      RegistrasiKaryawanApi.getJenjangPendidikan(),
      JabatanApi.getDropdown(),
      StatusKaryawanApi.getDropdown(),
      LokasiKerjaApi.getDropdown(),
      WilayahApi.getProvinsi(),
      RegistrasiKaryawanApi.getBank(),
    ])
      .then(
        Axios.spread((karyawan, jenjang, jabatan, status, lokasi, provinsi, bank) => {
          setDataKaryawan(karyawan.data.data);
          setDataJenjangPendidikan(jenjang.data.data);
          setDataJabatan(jabatan.data.data);
          setDataStatusKaryawan(status.data.data);
          setDataLokasiKerja(lokasi.data.data);
          setDataProvinsi(provinsi.data.data);
          setDataBank(bank.data.data);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Ubah Pekerja");
    getInitialData();

    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const ColumnData = ({ title, className, children }) => (
    <div className={className ? className : "mb-4"}>
      <h6
        className="ml-1 text-dark"
        style={{
          fontSize: "17px",
          marginBottom: "12.5px",
        }}
      >
        <b>{title}</b>
      </h6>
      <div className="bg-white p-2 px-3 shadow-sm rounded">{children}</div>
    </div>
  );

  const DataPribadi = () => (
    <ColumnData title="Data Pribadi">
      <FormDataPribadi dataKaryawan={dataKaryawan} getInitialData={getInitialData} />
    </ColumnData>
  );

  const DataPendidikan = () => (
    <ColumnData title="Data Pendidikan">
      <FormDataPendidikan
        dataKaryawan={dataKaryawan}
        dataJenjangPendidikan={dataJenjangPendidikan}
        getInitialData={getInitialData}
      />
    </ColumnData>
  );

  const DataAkun = () => (
    <ColumnData title="Data Akun">
      <FormDataAkun dataKaryawan={dataKaryawan} getInitialData={getInitialData} />
    </ColumnData>
  );

  const DataKontak = () => (
    <ColumnData title="Data Kontak">
      <FormDataKontak dataKaryawan={dataKaryawan} getInitialData={getInitialData} />
    </ColumnData>
  );

  const DataAlamat = () => (
    <ColumnData title="Data Alamat" className="mb-3">
      <FormDataAlamat
        dataKaryawan={dataKaryawan}
        dataProvinsi={dataProvinsi}
        getInitialData={getInitialData}
      />
    </ColumnData>
  );

  const DataKepegawaian = () => (
    <ColumnData title="Data Pekerja">
      <FormDataKepegawaian
        dataKaryawan={dataKaryawan}
        dataStatusKaryawan={dataStatusKaryawan}
        dataLokasiKerja={dataLokasiKerja}
        dataJabatan={dataJabatan}
        getInitialData={getInitialData}
      />
    </ColumnData>
  );

  const DataKeluarga = () => (
    <ColumnData title="Data Keluarga">
      <FormDataKeluarga
        idKaryawan={id}
        dataKaryawan={dataKaryawan}
        getInitialData={getInitialData}
      />
    </ColumnData>
  );

  const DataBank = () => (
    <ColumnData title="Data Perbankan">
      <FormDataBank
        dataKaryawan={dataKaryawan}
        dataBank={dataBank}
        getInitialData={getInitialData}
      />
    </ColumnData>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <hr />
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <Row>
            <Col md>
              <DataAkun />
              <hr />
              <DataKepegawaian />
              <hr />
              <DataKontak />
              <hr />
              <DataPendidikan />
            </Col>
            <Col md>
              <DataPribadi />
              <hr />
              <DataAlamat />
              <hr />
              <DataBank />
            </Col>
          </Row>
          <hr />
          <DataKeluarga />
        </>
      )}
    </CRUDLayout>
  );
};

export default UbahKaryawan;
