import Services from "../../../services";

class PenanggungJawabAnggaranApi {
  get() {
    return Services.get("/ang/jabatan");
  }
  create(value) {
    return Services.post("/ang/jabatan", value);
  }

  update(value) {
    return Services.put("/ang/jabatan", value);
  }
}

export default new PenanggungJawabAnggaranApi();
