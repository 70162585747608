import Services from "../../services";

class AuthApi {
  login(value) {
    return Services.post("/hrda/auth/login", value);
  }
  
  profile() {
    return Services.get("/hrda/auth/profile");
  }
}

export default new AuthApi();
