import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  tgl_penerima_petty_cash: Yup.string().required(
    "Pilih Tanggal Penerima Petty Cash"
  ),
  id_pekerja: Yup.string().required("Pilih Pekerja"),
  id_unit_produksi: Yup.string().required("Pilih Unit Produksi"),
  keterangan: Yup.string().required("Masukkan Keterangan"),
});
