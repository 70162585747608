import Services from '../../../services'

class JenisAnggaranAPi {
  get() {
    return Services.get('/ang/jenis_anggaran')
  }

  getPage(page) {
    return Services.get('/ang/jenis_anggaran/page/?per_page=10&page=' + page)
  }

  getKode() {
    return Services.get('/ang/jenis_anggaran/no_baru')
  }

  create(value) {
    return Services.post('/ang/jenis_anggaran', value)
  }

  update(value) {
    return Services.put('/ang/jenis_anggaran', value)
  }
  
  delete(value) {
    return Services.post('/ang/jenis_anggaran/delete', value)
  }
}

export default new JenisAnggaranAPi()