import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { Input, Select, ActionButton, Alert } from "../../../../../components";
import { RegistrasiKaryawanApi } from "../../../../../api";

const FormDataAkun = ({ dataKaryawan }) => {
  const [showAlert, setShowAlert] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const formInitialValues = {
    id_karyawan: dataKaryawan.id_karyawan,
    password: "",
    password_confirmation: "",
    username: dataKaryawan.username,
  };

  const formValidationSchema = Yup.object().shape({
    username: Yup.string().required("Masukan Username"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Konfirmasi Password tidak sama dengan Password"
    ),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    RegistrasiKaryawanApi.updateUser(values)
      .then(() => {
        setShowAlert({
          show: true,
          variant: "primary",
          text: "Ubah data sukses!",
        });
      })
      .catch((err) => {
        setShowAlert({
          show: true,
          variant: "danger",
          text: `Ubah data gagal! (${err})`,
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
          <form className="py-2" onSubmit={handleSubmit}>
            <Alert
              show={showAlert.show}
              showCloseButton={true}
              text={showAlert.text}
              variant={showAlert.variant}
              onClose={() =>
                setShowAlert({
                  ...showAlert,
                  show: false,
                })
              }
            />
            <Input
              type="text"
              label="Username"
              name="username"
              value={values.username}
              error={errors.username && touched.username && true}
              errorText={errors.username}
              onChange={handleChange}
            />
            <Input
              type="password"
              label="Ubah Password"
              placeholder="Kosongkan jika tidak diubah"
              name="password"
              value={values.password}
              error={errors.password && touched.lembagpassworda_pendidikan && true}
              errorText={errors.password}
              onChange={handleChange}
            />
            <Input
              type="password"
              label="Konfirmasi Password"
              name="password_confirmation"
              value={values.password_confirmation}
              error={errors.password_confirmation && touched.password_confirmation && true}
              errorText={errors.password_confirmation}
              onChange={handleChange}
            />
            <div className="d-flex justify-content-end mt-3">
              <ActionButton
                type="submit"
                variant="success"
                text="Ubah Data Akun"
                loading={isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FormDataAkun;
