import { useQuery } from 'react-query';
import { useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { DataStatus, Approval } from 'components';
import PenerimaPettyCashApi from '../__PenerimaPettyCashApi__';

export const CatatanApprovalPenerimaPettyCash = () => {
  const { values } = useFormikContext();
  const { data: approval, isLoading: isLoadingApproval } = useQuery(
    ['stakeholder', values.no_penerima_petty_cash],
    () =>
      PenerimaPettyCashApi.getSingle({
        no_penerima_petty_cash: values.no_penerima_petty_cash,
      }).then((res) => res.data.data ?? []),

    {
      enabled: Boolean(values.no_penerima_petty_cash),
    }
  );
  return (
    <Row>
      <Col>
        {isLoadingApproval ? (
          <DataStatus
            text="Memuat Data Approval..."
            loading={isLoadingApproval}
          />
        ) : (
          <Approval title="Penerima Petty Cash" data={approval} />
        )}
      </Col>
    </Row>
  );
};
