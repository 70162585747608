import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DeleteButton,
  UpdateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  Alert,
  Input,
  Pagination,
  TextArea,
  Switch,
  Select,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
} from "../../../../components";
import { PangkatApi } from "../../../../api";
import { TableNumber } from "../../../../utilities";

const Pangkat = ({ setNavbarTitle }) => {
  // Title
  const title = "Pangkat";

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(true);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");

  // menangani modal form tambah
  const [isCreateForm, setIsCreateForm] = useState(false);
  // menangani modal form ubah
  const [isUpdateform, setIsUpdateform] = useState(false);
  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  // data jenis anggaran
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // menampung data yang akan diubah
  const [updateData, setUpdateData] = useState([]);
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    searchKey: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    setIsSearching(false);

    // request data ke server
    PangkatApi.getPage(page, dataLength, searchKey)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  // menangani hide/show data
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_pangkat: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? PangkatApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : PangkatApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault();

    // set loading
    setIsLoading(true);

    // nilai dari form search
    const key = searchKey;

    PangkatApi.search(key)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setAlertConfig({
          variant: "primary",
          searchKey: key,
        });
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        });
      })
      .finally(() => {
        setPage(1);
        setIsSearching(true);
        setIsLoading(false);
        setShowAlert(true);
      });
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title);

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // modal tambah
  const TambahModal = () => {
    // nilai awal form
    const formInitialValues = {
      jenis_pangkat: "",
      nama_pangkat: "",
      keterangan: "",
    };

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      jenis_pangkat: Yup.string().required("Pilih jenis pangkat"),
      nama_pangkat: Yup.string().required("Masukan nama pangkat"),
    });

    // request tambah data ke server
    const formSubmitHandler = (values) => {
      PangkatApi.create(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal!`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsCreateForm(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="Jenis Pangkat"
                  placeholder="Masukan jenis pangkat"
                  name="jenis_pangkat"
                  defaultValue={values.jenis_pangkat}
                  error={errors.jenis_pangkat && touched.jenis_pangkat && true}
                  errorText={errors.jenis_pangkat}
                  onChange={handleChange}
                >
                  <option value="">Pilih jenis pangkat</option>
                  <option value="FN">Fungsional</option>
                  <option value="ST">Struktural</option>
                </Select>
                <Input
                  label="Nama Pangkat"
                  placeholder="Masukan nama pangkat"
                  name="nama_pangkat"
                  value={values.nama_pangkat}
                  error={errors.nama_pangkat && touched.nama_pangkat && true}
                  errorText={errors.nama_pangkat}
                  onChange={handleChange}
                />
                <TextArea
                  label="Keterangan"
                  placeholder="Masukan keterangan"
                  name="keterangan"
                  value={values.keterangan}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // modal ubah
  const UbahModal = () => {
    // nilai awal form
    const formInitialValues = {
      jenis_pangkat: updateData.jenis_pangkat === "Fungsional" ? "FN" : "ST",
      nama_pangkat: updateData.nama_pangkat,
      keterangan: updateData.keterangan,
    };

    // skema validasi form
    const formValidationSchema = Yup.object().shape({
      jenis_pangkat: Yup.string().required("Pilih jenis pangkat"),
      nama_pangkat: Yup.string().required("Masukan nama pangkat"),
    });

    // request ubah data ke server
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_pangkat: updateData.id_pangkat,
        ...values,
      };

      PangkatApi.update(finalValues)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err})`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsUpdateform(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateform} onHide={() => setIsUpdateform(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="Jenis Pangkat"
                  placeholder="Masukan jenis pangkat"
                  name="jenis_pangkat"
                  defaultValue={values.jenis_pangkat}
                  error={errors.jenis_pangkat && touched.jenis_pangkat && true}
                  errorText={errors.jenis_pangkat}
                  onChange={handleChange}
                >
                  <option value="">Pilih jenis pangkat</option>
                  <option value="FN">Fungsional</option>
                  <option value="ST">Struktural</option>
                </Select>
                <Input
                  label="Nama Pangkat"
                  placeholder="Masukan nama pangkat"
                  name="nama_pangkat"
                  value={values.nama_pangkat}
                  error={errors.nama_pangkat && touched.nama_pangkat && true}
                  errorText={errors.nama_pangkat}
                  onChange={handleChange}
                />
                <TextArea
                  label="Keterangan"
                  placeholder="Masukan keterangan"
                  name="keterangan"
                  value={values.keterangan}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // modal hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const idData = deleteData.id_pangkat;
    const values = { id_pangkat: idData };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      PangkatApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err})`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <span>Nama Pangkat : {deleteData.nama_pangkat}</span>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Jenis Pangkat</ThFixed>
            <Th>Nama Pangkat</Th>
            <Th>Keterangan</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <UpdateButton
                    onClick={() => {
                      setUpdateData(val);
                      setIsUpdateform(true);
                    }}
                  />
                  <DeleteButton
                    onClick={() => {
                      setDeleteData(val);
                      setIsDeleteData(true);
                    }}
                  />
                  <Switch
                    id={toString(index + 1)}
                    checked={!val.is_hidden}
                    onChange={() => changeDataStatus(val.is_hidden, val.id_pangkat)}
                  />
                </div>
              </TdFixed>
              <TdFixed>{val.jenis_pangkat === "Struktural" ? "Struktural" : "Fungsional"}</TdFixed>
              <Td>{val.nama_pangkat}</Td>
              <Td>{val.keterangan}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* aLert */}
      {isSearching ? (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={`Hasil dari pencarian: ${alertConfig.searchKey}`}
          onClose={() => {
            setShowAlert(false);
            getData();
            setIsSearching(false);
          }}
        />
      ) : (
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
      )}

      {/* table section */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          // Ada data
          <Table />
        ) : (
          // Tidak ada data
          <DataStatus text="Tidak ada data" />
        )
      }

      {/* Modal */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default Pangkat;
